import React from 'react';
import styled from 'styled-components';
import {Link, graphql} from 'gatsby';
import NewsTeaser from '../components/Home/NewsTeaser';
import Layout from './../components/Layout';

const Page = styled.section`
  border-top: 1px solid #e20074;
  padding-top: 80px;
`;

const NewsWrapper = styled.section`
  padding-bottom: 140px;
`;

const Title = styled.h1`
  width: 75%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  font-size: 40px;
  margin-bottom: 1.2em;
`;

const Copy = styled.div`
  width: 83%;
  margin: 0 auto;

  p,
  ul {
    font-size: 20px;
    color: #4b4b4b;
  }
  img {
    border: 0;
    display: block;
    margin: 40px auto;
  }
`;

export default ({data, location}) => (
  <Layout location={location}>
    <Page className="main">
      <NewsWrapper className="news">
        <div className="container">
          <Title>Privacy Policy</Title>
          {data ? (
            <Copy
              dangerouslySetInnerHTML={{
                __html: data.datoCmsPagePrivacyPolicy.copy,
              }}
            />
          ) : null}
        </div>
      </NewsWrapper>
    </Page>
  </Layout>
);

export const query = graphql`
  query PrivacyQuery {
    datoCmsPagePrivacyPolicy {
      copy
    }
  }
`;
